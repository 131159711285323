<template>
  <div>
    <Breadcrumb class="m-0" v-if="false">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'VideoClasses'
            }"
          >
            視頻課程
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="video-box">
      <div class="edu-play-left" v-if="lesson">
        <div class="videoContainer">
          <template>
            <template v-if="lesson.preview_video_url_key">
              <iframe
                v-if="
                  isAfterPremiereDate
                    ? lesson.video_url_key
                    : lesson.preview_video_url_key
                "
                :src="
                  isAfterPremiereDate
                    ? lesson.video_url
                    : lesson.preview_video_url
                "
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
              <img
                v-else
                :src="isAfterPremiereDate ? lesson.cover : lesson.preview_cover"
              />
            </template>
            <img v-else :src="lesson.default_cover" />
          </template>
        </div>
        <h2 class="class-title">
          {{ myTitle }}
        </h2>
        <div class="block-list-item-info">
          <div class="block-list-item-info-player">
            <i class="far fa-play-circle"></i>
            <span class="ml-1">26.4萬</span>
          </div>
          <span>共20課時</span>
        </div>
        <div class="description">
          {{ lesson.description.en }}
        </div>
        <hr />
        <div>
          <h6 class="mb-3">发布者</h6>
          <div class="speaker-avatar">
            <div>
              <el-avatar
                :size="90"
                alt="J"
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Karen.png"
              ></el-avatar>
            </div>
            <h6>{{ lesson.instructor_name }}</h6>
          </div>
        </div>
        <div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="課程信息" name="classInfo">
              <div>
                這裡寫課程的一些基本的信息
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="edu-play-right">
        <div v-if="false">
          <el-button type="success" style="width: 100%;">
            優惠價 100 Coins，立即解鎖！
          </el-button>
        </div>
        <div>
          <p class="mb-2">課程目錄</p>
          <div class="section-card-list">
            <el-collapse accordion>
              <el-collapse-item
                v-for="(subject, subjectIndex) in subjects"
                :key="subjectIndex"
              >
                <template slot="title">
                  <h5 class="text-success  mt-4 mb-4">
                    {{ `UNIT ${subjectIndex + 1} - ${subject.name}` }}
                  </h5>
                </template>
                <div
                  v-for="(lesson, i) in subject.materials"
                  :key="i"
                  :class="
                    lessonIndex === i
                      ? 'section-season p-1 active'
                      : 'section-season p-1'
                  "
                  @click="setPage(i)"
                >
                  <div class="season-order p-1">
                    {{ i + 1 }}
                  </div>
                  <div>
                    <div class="season-title">
                      <b>{{ lesson.title }}</b>
                    </div>
                    <div class="season-info">
                      <i class="far fa-clock"></i> 02:30
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <!-- <div class="mt-4">
          <p class="mb-2">相關課程</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import moment from "moment";

export default {
  components: { Breadcrumb },

  mixins: [],

  props: ["subjects"],
  data() {
    return {
      lesson: null,
      activeName: "classInfo"
      // subjects: []
    };
  },
  computed: {
    myTitle() {
      return this.course ? this.course.name : "課程的標題";
    },
    moment() {
      return moment;
    },
    isAfterPremiereDate() {
      return moment(new Date()).isAfter(this.lesson.premiere_date);
    },
    lessonIndex() {
      return this.$route.query.lessonIndex
        ? parseInt(this.$route.query.lessonIndex)
        : 1;
    }
  },
  watch: {},

  mounted() {
    let lesson = {
      id: 1,
      order: 1,
      title: {
        en: "How to Structure Your TOEFL Speaking Question 2",
        cn: "How to Structure Your TOEFL Speaking Question 2",
        tw: "How to Structure Your TOEFL Speaking Question 2"
      },
      instructor_name: "Jeff",
      description: {
        en:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!",
        cn:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!",
        tw:
          "In this video, TOEFL instructor and test expert Jeff explains everything you need to know about TOEFL Speaking Question 2. Learn the structure and strategies of the task, and see Jeff walk through constructing a perfect-score response using a real official question from the test maker!"
      },
      video_url: "https://www.youtube.com/embed/BQGm6qaC97g",
      preview_video_url: "https://www.youtube.com/embed/uXyLVfH0te0",
      cover:
        "https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/lesson/how_to_structure_your_toefl_speaking_question_2.png",
      preview_cover:
        "https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/lesson/lessonPreviewQuestion2.png",
      label: {
        en: "Speaking",
        cn: "口说",
        tw: "口說"
      },
      premiere_date: "2022-01-14T17:00:00.000000Z",
      created_at: "2022-01-10T10:37:07.000000Z",
      updated_at: "2022-02-06T18:52:27.000000Z",
      default_cover: null
    };
    this.lesson = {
      ...lesson,
      preview_video_url_key: lesson.preview_video_url.replace(
        "https://www.youtube.com/embed/",
        ""
      ),
      video_url_key: lesson.video_url.replace(
        "https://www.youtube.com/embed/",
        ""
      )
    };
    
  },

  methods: {
    setPage(page) {
      this.$router.push({
        query: {
          lessonIndex: page
        }
      });
    }
  }
};
</script>

<style scoped>
.video-box {
  display: flex;
  margin-top: 1rem;
}
.edu-play-left {
  flex: 1;
  margin-bottom: 3rem;
}
.videoContainer {
  border: 1px solid #ccc;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::v-deep .el-collapse-item__header {
  height: auto;
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header:hover{
 background-color: #fafafa;
}
.edu-play-right {
  min-width: 28%;
  max-width: 420px;
  box-sizing: border-box;
  padding: 0 0 10px 30px;
  /* background-color: #fafafa; */
}
.section-card-list {
  min-height: 30rem;
  max-height: 50rem;
  /* overflow: auto; */
}
.section-season {
  position: relative;
  display: flex;
  margin-top: 0.5rem;
  background: #f6f7f8;
  border: 0.5px solid transparent;
  border-radius: 6px;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
}
.season-order {
  width: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #9499a0;
  text-align: center;
}
.season-title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #18191c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 196px;
}
.season-info {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #61666d;
}
.active {
  cursor: default;
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.section-season:hover {
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.class-title {
  margin-top: 1rem;
}
.block-list-item-info {
  display: flex;
  width: 10rem;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2rem;
  color: #61666d;
}
.description {
  color: #9499a0;
  white-space: pre-line;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: center;
}
</style>
