var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(false)?_c('Breadcrumb',{staticClass:"m-0"},[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'VideoClasses'
          }}},[_vm._v(" 視頻課程 ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2):_vm._e(),_c('div',{staticClass:"video-box"},[(_vm.lesson)?_c('div',{staticClass:"edu-play-left"},[_c('div',{staticClass:"videoContainer"},[[(_vm.lesson.preview_video_url_key)?[(
                _vm.isAfterPremiereDate
                  ? _vm.lesson.video_url_key
                  : _vm.lesson.preview_video_url_key
              )?_c('iframe',{attrs:{"src":_vm.isAfterPremiereDate
                  ? _vm.lesson.video_url
                  : _vm.lesson.preview_video_url,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('img',{attrs:{"src":_vm.isAfterPremiereDate ? _vm.lesson.cover : _vm.lesson.preview_cover}})]:_c('img',{attrs:{"src":_vm.lesson.default_cover}})]],2),_c('h2',{staticClass:"class-title"},[_vm._v(" "+_vm._s(_vm.myTitle)+" ")]),_vm._m(0),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.lesson.description.en)+" ")]),_c('hr'),_c('div',[_c('h6',{staticClass:"mb-3"},[_vm._v("发布者")]),_c('div',{staticClass:"speaker-avatar"},[_c('div',[_c('el-avatar',{attrs:{"size":90,"alt":"J","src":"https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Karen.png"}})],1),_c('h6',[_vm._v(_vm._s(_vm.lesson.instructor_name))])])]),_c('div',[_c('el-tabs',{model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"課程信息","name":"classInfo"}},[_c('div',[_vm._v(" 這裡寫課程的一些基本的信息 ")])])],1)],1)]):_vm._e(),_c('div',{staticClass:"edu-play-right"},[(false)?_c('div',[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"success"}},[_vm._v(" 優惠價 100 Coins，立即解鎖！ ")])],1):_vm._e(),_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v("課程目錄")]),_c('div',{staticClass:"section-card-list"},[_c('el-collapse',{attrs:{"accordion":""}},_vm._l((_vm.subjects),function(subject,subjectIndex){return _c('el-collapse-item',{key:subjectIndex},[_c('template',{slot:"title"},[_c('h5',{staticClass:"text-success  mt-4 mb-4"},[_vm._v(" "+_vm._s(("UNIT " + (subjectIndex + 1) + " - " + (subject.name)))+" ")])]),_vm._l((subject.materials),function(lesson,i){return _c('div',{key:i,class:_vm.lessonIndex === i
                    ? 'section-season p-1 active'
                    : 'section-season p-1',on:{"click":function($event){return _vm.setPage(i)}}},[_c('div',{staticClass:"season-order p-1"},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('div',[_c('div',{staticClass:"season-title"},[_c('b',[_vm._v(_vm._s(lesson.title))])]),_c('div',{staticClass:"season-info"},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" 02:30 ")])])])})],2)}),1)],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block-list-item-info"},[_c('div',{staticClass:"block-list-item-info-player"},[_c('i',{staticClass:"far fa-play-circle"}),_c('span',{staticClass:"ml-1"},[_vm._v("26.4萬")])]),_c('span',[_vm._v("共20課時")])])}]

export { render, staticRenderFns }